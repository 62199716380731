<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<template>
  <div>
    <h3>BIENES</h3>
    <div class="row">
      <app-loading class="col d-block" v-if="loading"></app-loading>
      <div class="col-lg-3 col-6" v-for="(l, i) in list" :key="i">
        <div class="card mb-3">
          <div class="card-body">
            <img
              :src="l.product.file_id | imageUrl"
              alt=""
              class="app_img mb-2"
            />
            <div>
              {{ l.product.name }}
            </div>
            <b>{{ l.product.code }}</b>
          </div>
        </div>
      </div>
    </div>
    <app-paginate @change="changePageHandler" ref="elPaginate"></app-paginate>
  </div>
</template>
<script>
import routeMixin from "../../utils/routeMixin.js";
import { StoreService } from "../StoreService";

export default {
  mixins: [routeMixin],

  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    list: [],
    page: 1,
    loading: false
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  created() {
    this.page = this.$route.query.page || 1;
  },

  mounted() {
    this.getList();
  },

  methods: {
    changePageHandler(newPage) {
      this.page = newPage;
      this.addQueryParam("page", newPage);
      this.getList();
    },
    async getList() {
      this.list = [];
      this.loading = true;
      let responseData = await StoreService.getProductGroups({
        page: this.page,
        search: ""
      });
      this.loading = false;
      this.list = responseData.data;
      this.page = responseData.current_page;
      this.$refs.elPaginate.setValues({
        total: responseData.total,
        per_page: responseData.per_page,
        current_page: responseData.current_page
      });
    }
  }
};
</script>

<style scoped>
img.app_img {
  object-fit: contain;
  width: 100%;
  height: 10rem;
}
</style>
